<template>
    <layout>        

         <v-row 
            align="center"
            justify="center"
             class="mt-6"
            v-for="(section, index) in sections"
            :key="index"
        > 
            <v-card                
                width="500"
            >  
                <v-card-title class="white--text pl-4 primary">
                    {{ section.label }}
                </v-card-title>
                <v-card-text
                    class="pb-0 mt-3"
                >
                    {{ section.subtitle }}
                </v-card-text>
                <v-row
                    align="center"
                    justify="center"
                    v-for="(field, key) in sectionFields(index)"
                    :key="key"
                    class="px-4"
                >
                    <v-col cols="12">

                        <v-checkbox
                            v-if="field.type == 'checkbox'"
                            v-bind:label="field.label"
                            v-model="medicalDataRecordLocal[field.model]"
                            @change="update(field.model, index)"
                        >
                            <template v-slot:append>

                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>

                            </template>
                        </v-checkbox>

                        <v-text-field
                            v-if="field.type == 'text'"
                            v-bind:label="field.label"
                            v-model="medicalDataRecordLocal[field.model]"
                            @blur="update(field.model, index)"
                        >
                            <template v-slot:append>

                                <v-fade-transition>
                                    <v-progress-circular
                                        v-if="field.save"
                                        size="16"
                                        width="3"
                                        color="info"
                                        indeterminate
                                    ></v-progress-circular>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveSuccess"
                                        small
                                        color="info"                                        
                                    >
                                        mdi-check-all
                                    </v-icon>
                                </v-fade-transition>

                                <v-fade-transition>    
                                    <v-icon
                                        v-if="field.saveError"
                                        small
                                        color="red"                                        
                                    >
                                        mdi-alert-circle
                                    </v-icon>
                                </v-fade-transition>

                            </template>
                        </v-text-field>

                    </v-col>
                </v-row>                    
            </v-card>                      
         </v-row>
                           
    </layout>    
</template>

<script>
import Layout from './layouts/Registration';
import { mapActions, mapMutations } from 'vuex'

export default {
    name: 'HealthData',
    components: {
        Layout,
    },

    created: function () {        
        this.setOverlay(true);
    },

    watch: {
        // medicalDataRecord: {
        //     handler (val) {
        //         console.log(val);
        //     }
        // }
    },

    data: () => ({
        dataFields: [],              
        medicalDataRecordLocal: {}  
    }),

    mounted: function () {        
        this.initialize();        
    },
    computed: {
                
        sections () {
            let sections = [];
            this.dataFields.forEach(fieldArray => {
                fieldArray.filter(field => {
                    if(field.break){
                        sections.push(field);
                        return field;
                    }
                })
            })
           
            return sections;
        }
    },         
    methods: {        
        ...mapMutations({            
            setOverlay: 'auth/setOverlay',
            setDataMedical: 'form/setStudentDataMedical',            
        }),

        ...mapActions({
            getDataMedical: 'form/getStudentDataMedical',
            postDataMedical: 'form/postStudentDataMedical',
        }),

        async initialize () {
            try {
                const { data } = await this.getDataMedical();
                this.setHealthRecord(data);
            } catch (error) {
                if(error.response){
                    console.log(error.response);
                }
                else console.log(error);
            }
            
        },
        
        setHealthRecord (data) {
            Object.keys(data).forEach(key => {
                if(data[key] == 0)
                this.medicalDataRecordLocal[key] = false;
                else if(data[key] == 1)
                this.medicalDataRecordLocal[key] = true;
                else this.medicalDataRecordLocal[key] = data[key];
            })            

            this.$nextTick(() => {
                this.setOverlay(false);
            })

            this.dataFields = [
                [
                    {
                        label: 'Immunization Records', 
                        subtitle: 'Please indicate whether your child was immunized for the following',
                        break: true
                    },
                    {
                        label: 'Hepatitis', 
                        model: 'hepatitis',
                        type: 'checkbox',
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: 'Polio', 
                        model: 'polio',
                        type: 'checkbox',
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: 'Diphtheria', 
                        model: 'diphtheria',
                        type: 'checkbox',
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: 'Tetanus', 
                        model: 'tetanus',
                        type: 'checkbox',
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: 'Yellow Fever', 
                        model: 'yellow_fever',
                        type: 'checkbox',
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: 'Measles', 
                        model: 'measles',
                        type: 'checkbox',
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: 'T.B.', 
                        model: 'tb',
                        type: 'checkbox',
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: 'Chicken Pox', 
                        model: 'chicken_pox',
                        type: 'checkbox',
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: 'Typhoid', 
                        model: 'typhoid',
                        type: 'checkbox',
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: 'Rheumatic Fever', 
                        model: 'rheumatic_fever',
                        type: 'checkbox',
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },                   
                ],
                [
                    {
                        label: 'Health Conditions', 
                        subtitle: 'Please indicate whether your child suffers from any of the following',
                        break: true
                    },
                    {
                        label: 'Poor Eyesight', 
                        model: 'poor_eyesight',
                        type: 'checkbox',
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: 'Poor Hearing', 
                        model: 'poor_hearing',
                        type: 'checkbox',
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: 'Diabetes', 
                        model: 'diabetes',
                        type: 'checkbox',
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: 'Asthma', 
                        model: 'asthma',
                        type: 'checkbox',
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: 'Epilepsy', 
                        model: 'epilepsy',
                        type: 'checkbox',
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                ],
                [
                    {
                        label: 'Other Health Conditions', 
                        subtitle: '',
                        break: true
                    },
                    {
                        label: 'Other Health Condition', 
                        type: 'text', 
                        placeholder: 'State any other health condition', 
                        model: 'other',
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },                    
                    {
                        label: 'Allergies', 
                        type: 'text', 
                        placeholder: 'State any allergies', 
                        model: 'allergies',
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                    {
                        label: 'Medications', 
                        type: 'text', 
                        placeholder: 'State any Medication', 
                        model: 'medication',
                        save: false,
                        saveSuccess: false,
                        saveError: false,
                    },
                ]
                 
            ]
        
        },

        sectionFields (section) {            
            let fields = [];
            this.dataFields.forEach((fieldArray, index) => {
                if(index == section){
                    fields = fieldArray.filter(field => {
                        if(!field.break) return field;
                    })
                }
            })
            
            return fields;
        },
        
        async update (model, index) {           
            let record = {};
            let response = await this.setSaveStatus(model, index);
            console.log(response);
            try {
                Object.keys(this.medicalDataRecordLocal).forEach(key => {
                    if(this.medicalDataRecordLocal[key] === true)
                    record[key] = 1;
                    else if (this.medicalDataRecordLocal[key] === false)
                    record[key] = 0;
                    else record[key] = this.medicalDataRecordLocal[key];
                })
                //console.log(this.medicalDataRecordLocal);
                this.setDataMedical(record);
                console.log(record);
                response = await this.postDataMedical();
                console.log(response.status);
                response = await this.setSaveStatus(model, index, 'saved');
                console.log(response);
            } catch (error) {
                if(error.response)
                console.log(error.response);
                else console.log(error);
                await this.setSaveStatus(model, index, 'error'); 
            }
        },

        setSaveStatus (model, index, status = 'saving') {        
            for(const field of this.dataFields[index]) {                
                if(field.model == model){                    
                    switch (status){
                        case 'saving':                            
                            field.saveSuccess = false;
                            field.saveError = false;                            
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.save = true;                                                                      
                                    resolve(status);
                                }, 600)
                            })                            
                            
                        case 'saved':
                            field.save = false;
                            field.saveError = false;
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.saveSuccess = true; 
                                    resolve(status);
                                }, 600)
                            })
                            
                        case 'error':
                            field.save = false;
                            field.saveSuccess = false;
                            return new Promise(resolve => {
                                setTimeout(() => {
                                    field.saveError = true;
                                    resolve(status);
                                }, 600)
                            })
                            
                        default:
                            console.log('no match found');
                            return new Promise(resolve => {
                                resolve('no match');
                            });             
                    }                    
                }
            }                
        }
       
    }
}
</script>